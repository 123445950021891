import { graphql } from 'gatsby'
import { IAboutPageProps } from 'interfaces/PageInterfaces'
import React from 'react'
import { Helmet } from 'react-helmet'
import {
  BannerSection,
  ConnectSection,
  FAQSection,
  MissionSection,
  StorySection,
  TeamSection,
} from 'sections/AboutUs'

const About = ({ data }: IAboutPageProps) => {
  const {
    QandA,
    teamMembers,
    bannerText,
    missionText,
    storyArticles,
  } = data

  const storyArticlesArgs = [
    {
      title: storyArticles.story1,
      description: storyArticles.story1Description,
    },
    {
      title: storyArticles.story2,
      description: storyArticles.story2Description,
    },
    {
      title: storyArticles.story3,
      description: storyArticles.story3Description,
    },
    {
      title: storyArticles.story4,
      description: storyArticles.story4Description,
    },
  ]

  return (
    <>
      <Helmet title="About Us" defer={false} />
      <BannerSection {...bannerText} />
      <MissionSection {...missionText} />
      <StorySection
        title={storyArticles.title}
        storyArticles={storyArticlesArgs}
      />
      <TeamSection teamMembers={teamMembers.nodes} />
      <FAQSection questions={QandA.nodes} />
      <ConnectSection />
    </>
  )
}

export const pageData = graphql`
  query {
    QandA: allContentfulAboutQa {
      nodes {
        question
        answer {
          answer
        }
      }
    }
    teamMembers: allContentfulAboutTeamMember {
      nodes {
        designation
        fullName
        description {
          description
        }
        profilePicture {
          gatsbyImage(width: 300, quality: 80)
        }
      }
    }
    bannerText: contentfulAboutPage {
      header: block1BannerHeader
      subHeader: block1BannerSubHeader
    }
    missionText: contentfulAboutPage {
      mission: block2MissionHeader
      description: block2MissionSubHeader
    }
    storyArticles: contentfulAboutPage {
      story1: block3StoryArticle1Title
      story1Description: block3StoryArticle1Description {
        description: block3StoryArticle1Description
      }
      story2: block3StoryArticle2Title
      story2Description: block3StoryArticle2Description {
        description: block3StoryArticle2Description
      }
      story3: block3StoryArticle3Title
      story3Description: block3StoryArticle3Description {
        description: block3StoryArticle3Description
      }
      story4: block3StoryArticle4Title
      story4Description: block3StoryArticle4Description {
        description: block3StoryArticle4Description
      }
      title: block3StoryTitle
    }
  }
`

export default About
