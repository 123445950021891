import { StyledText } from 'components/common'
import React from 'react'
import { FAQ, FAQContainer, Normalizer, Wrapper } from './styled-components'
import QuestionMarkSvg from 'images/svg/question-mark.svg'

const FAQSection = ({
  questions,
}: {
  questions: {
    question: string
    answer: {
      answer: string
    }
  }[]
}) => {
  return (
    <Wrapper>
      <StyledText textAlign="center" className="heading">
        Let’s answer some of your questions
      </StyledText>
      <FAQContainer>
        {questions.map(({ question, answer }, index) => (
          <Normalizer index={index + 1} key={index}>
            <FAQ>
              <div className="question">
                <QuestionMarkSvg />
                <p>{question}</p>
              </div>
              <p className="answer">{answer.answer}</p>
            </FAQ>
          </Normalizer>
        ))}
      </FAQContainer>
    </Wrapper>
  )
}

export default FAQSection
