import { StyledText, WaveWrapper } from 'components/common'
import BannerWoman from 'images/svg/bannerWomen.svg'
import React from 'react'
import { TextContainer, FlexWrapper } from './styled-components'

import BannerSparkle from 'images/svg/bannerSparkle.svg'

const BannerSection = ({
  header,
  subHeader,
}: {
  header: string
  subHeader?: string
}) => {
  const sparkleMap = [1, 2, 3, 4, 5]
  const isSubheader = subHeader !== undefined && subHeader !== '!'
  return (
    <WaveWrapper color="lightGreen">
      {sparkleMap.map((sparkle, index) => {
        const className = `banner-sparkle sparkle-${sparkle}`
        return <BannerSparkle className={className} key={index} />
      })}
      <FlexWrapper>
        <TextContainer>
          <StyledText fontSize="72px">{header}</StyledText>
          {isSubheader && (
            <StyledText
              textAlign="center"
              fontSize="24px"
              fontType="regular"
              fontWeight={400}
              className="banner-text"
            >
              {subHeader}
            </StyledText>
          )}
        </TextContainer>
      </FlexWrapper>
    </WaveWrapper>
  )
}

export default BannerSection
