import styled from 'styled-components'

export const Wrapper = styled.section`
  margin: 100px 165px;
  padding: 100px 125px;
  background-color: rgba(207, 175, 235, 0.1);
  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    margin: 40px 100px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    margin: 30px 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    margin: 0;
    padding: 30px 0;
  }
`

export const SubscribeArticle = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.placeholders.maxWidth}
  @media (min-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding-inline: 9%;
  }
  margin-top: 30px;
  .content {
    width: 30%;
  }
  form {
    width: 50%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    flex-direction: column;
    .content {
      width: 100%;
    }
    form {
      width: 100%;

      .link,
      .custom-button {
        width: 100%;
      }
    }
    width: 100%;
    padding-inline: 20px;
  }
`
