import styled from 'styled-components'

export const Wrapper = styled.section`
  & > :first-child {
    background-color: #fcfcfc;
    padding-top: 32px;
    margin-bottom: 0;
  }
`

export const ArticleContainer = styled.article<{
  variant?: string | false
}>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ variant }) =>
    variant === 'reverse' ? 'row-reverse' : 'row'};
  ${({ variant }) => variant === 'reverse' && 'background-color: white;'}
  background-color: ${({ variant }) =>
    variant === 'reverse' ? 'white' : '#fcfcfc'};
  align-items: center;
  padding: 48px 165px;
  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding-inline: 100px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding-inline: 50px;
    gap: 60px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding-inline: 20px;
    flex-direction: column;
  }
  @media (min-width: 2000px) {
    padding: 48px 19vw;
  }
  & > :first-child {
    width: 50%;
    max-width: 700px;
    @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
      width: 100%;
    }
  }

  & > :last-child {
    width: auto;
    height: 30vh;
    @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
      width: 80%;
      height: auto;
      margin-top: 40px;
    }
  }
`
