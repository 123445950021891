const handleFormSubmit = (
  event: React.FormEvent<HTMLFormElement>,
  onSuccess: (value: React.SetStateAction<boolean>) => void,
  isTurkish?: boolean,
) => {
  event.preventDefault()
  const form = event.target as HTMLFormElement
  const formData: any = new FormData(form)
  const data: any = {}

  if (isTurkish) {
    data.isTurkish = true
  }

  for (const [key, value] of formData.entries()) {
    data[key] = value
  }
  // append checkbox values to formData from form
  const checkboxes: any = form.querySelectorAll(
    'input[type="checkbox"]',
  )

  if (checkboxes) {
    data.services = []
    for (const checkbox of checkboxes) {
      if (checkbox.checked) {
        data.services.push(checkbox.id)
      }
    }
  }

  return fetch(`${process.env.API_URL}/users/sendMail`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(() => {
      onSuccess(true)
    })
    .catch(() => {
      alert('Something went wrong!')
    })
}

export default handleFormSubmit
