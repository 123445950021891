import { FlexArticle, StyledText } from 'components/common'
import { currentIconEnums } from 'interfaces/FlexArticleInterfaces'
import React from 'react'

import {
  ArticleContainer,
  Wrapper,
} from './styled-components'
import WebsiteWomanSvg from 'images/svg/websiteWoman.svg'
import TeacherSvg from 'images/svg/teacherinmonitor.svg'
import SittingGirlSvg from 'images/svg/sitting-girl-with-laptop.svg'
import WomanOnBooksSvg from 'images/svg/womanOnBooks.svg'

const StorySection = ({
  title,
  storyArticles,
}: {
  title: string
  storyArticles: {
    title: string
    description: {
      description: string
    }
  }[]
}) => {
  const articleIconEnums = [
    currentIconEnums.waves,
    currentIconEnums.ThreeXThreePurple,
    currentIconEnums.TwoXTwo,
    currentIconEnums.wavesReversePurple,
  ]

  const storySvg = [
    WebsiteWomanSvg,
    TeacherSvg,
    SittingGirlSvg,
    WomanOnBooksSvg,
  ]

  return (
    <>
      <Wrapper>
        <StyledText textAlign="center" className="heading">
          {title}
        </StyledText>
        {storyArticles.map(
          ({ title, description }, index) => {
            const remainder = index % 4
            const ArticleSvg = storySvg[remainder]
            return (
              <ArticleContainer
                variant={remainder % 2 !== 0 && 'reverse'}
              >
                <FlexArticle
                  icon={articleIconEnums[remainder]}
                  themeColor="#8636CC"
                  title={title}
                  description={description.description}
                  gap={[18, 10]}
                />
                <ArticleSvg />
              </ArticleContainer>
            )
          },
        )}
      </Wrapper>
    </>
  )
}

export default StorySection
