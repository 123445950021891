import styled from 'styled-components'

export const Wrapper = styled.section`
  padding: 120px 165px;
  padding-bottom: 20px;
  ${({ theme }) => theme.placeholders.maxWidth}

  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 120px 100px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 120px 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 100px 20px;
  }
`

export const ArticleContainer = styled.article`
  margin-top: 60px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 40px;

  @media (max-width: 1850px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    grid-template-columns: repeat(1, 1fr);
  }
`
