import { StyledText, TeamMember } from 'components/common'
import { graphql, useStaticQuery } from 'gatsby'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React from 'react'

import { ArticleContainer, Wrapper } from './styled-components'

const TeamSection = ({
  teamMembers,
}: {
  teamMembers: {
    fullName: string
    designation: string
    description: {
      description: string
    }
    profilePicture: {
      gatsbyImage: IGatsbyImageData
    }
  }[]
}) => {
  return (
    <Wrapper>
      <StyledText textAlign="center" className="heading">
        Meet Our Team
      </StyledText>
      <ArticleContainer>
        {teamMembers.map(
          ({ profilePicture, fullName, designation, description }, index) => (
            <TeamMember
              key={index}
              profilePic={profilePicture.gatsbyImage}
              name={fullName}
              designation={designation}
              description={description.description}
            />
          ),
        )}
      </ArticleContainer>
    </Wrapper>
  )
}

export default TeamSection
