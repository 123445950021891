import { StyledText } from 'components/common'
import React from 'react'
import { Wrapper } from './styled-components'

const MissionSection = ({
  mission,
  description,
}: {
  mission: string
  description: string
}) => {
  return (
    <Wrapper>
      <StyledText
        fontSize="40px"
        textAlign="center"
        textColor="#06C68F"
        className="heading"
      >
        {mission}
      </StyledText>
      <StyledText
        variant="light"
        textAlign={'center'}
        className={'partner-text subheader'}
      >
        {description}
      </StyledText>
    </Wrapper>
  )
}

export default MissionSection
