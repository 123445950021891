import styled from 'styled-components'

export const Wrapper = styled.section`
  padding: 120px 165px;
  ${({ theme }) => theme.placeholders.maxWidth}

  .title {
    margin-bottom: 20px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 120px 100px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 120px 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wxs}) {
    padding: 100px 20px;
  }
`

export const FAQContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  @media (max-width: 1310px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

export const Normalizer = styled.div<{ index: number }>`
  display: flex;
  width: 40%;
  justify-content: ${({ index }) =>
    index % 2 !== 0 ? 'flex-start' : 'flex-end'};
  @media (max-width: 1310px) {
    justify-content: center;
    width: 100%;
  }
`

export const FAQ = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  min-width: 560px;
  .question {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      font-family: 'Poppins-SemiBold', sans-serif;
      font-size: 16px;
    }
  }
  .answer {
    font-family: 'Poppins', sans-serif;
    color: rgba(99, 99, 105, 1);
    font-size: 14px;
    line-height: 24px;
    white-space: pre-line;
  }

  @media (max-width: 1310px) {
    min-width: 100%;

    .answer {
      white-space: unset;
    }
  }
`
