import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 120px 5%;
  ${({ theme }) => theme.placeholders.maxWidth}
  @media (max-width: ${({ theme }) => theme.breakpoints.wl}) {
    padding: 100px 5%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.wm}) {
    padding: 70px 2%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.ws}) {
    padding: 40px 20px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.wxs}) {
    .partner-text {
      font-size: 22px;
      line-height: 38px;
    }
  }
`
