import {
  StyledButton,
  StyledText,
  StyledInput,
  CustomDialog,
} from 'components/common'
import handleFormSubmit from 'helpers/handleFormSubmit'
import React, { useState } from 'react'
import {
  SubscribeArticle,
  Wrapper,
} from './styled-components'

const ConnectSection = () => {
  const [isOpen, setIsOpen] = useState(false)

  const handleSubmit = (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault()
    setIsOpen(true)
    handleFormSubmit(e, setIsOpen)
  }
  return (
    <Wrapper>
      <StyledText textAlign="center" className="heading">
        Still have questions?
      </StyledText>
      <SubscribeArticle>
        <div className="content">
          <StyledText fontSize="24px">
            Let’s Connect
          </StyledText>
          <StyledText variant="light" className="subheader">
            Sign up to keep in touch and stay up to date
            with course offerings, new additions, and start
            dates.
          </StyledText>
        </div>
        <form onSubmit={handleSubmit}>
          <StyledInput
            id="name"
            label="Name*"
            type="name"
            placeholder="Enter your name"
            required
          />
          <StyledInput
            id="email"
            label="Email address*"
            type="name"
            placeholder="Enter your email address"
            required
          />
          <StyledInput
            id="phoneNumber"
            label="Phone number"
            type="number"
            placeholder="Enter your phone number"
          />
          <StyledButton type="submit">
            Register Your Interest
          </StyledButton>
        </form>
      </SubscribeArticle>
      <CustomDialog
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
      />
    </Wrapper>
  )
}

export default ConnectSection
